<template>
  <z-card data-cy="welcome-menu-card" class="welcome-card" width="100%">
    <template #content>
      <h4>{{ $t("welcome.welcome_menu_title") }} {{ hotel.nomeFantasia }}</h4>
      <p class="body-text">
        {{ $t("welcome.welcome_menu_description") }}
      </p>

      <z-card
        v-for="(option, index) of options"
        :key="index"
        style="padding: 0"
        width="100%"
        shadow
      >
        <template #content>
          <div v-if="option.isVisible" class="card-option">
            <z-icon size="54px" :color="option.color">
              {{ option.icon }}
            </z-icon>
            <div class="card-option-text">
              <div class="button-text">{{ option.title }}</div>
              <div class="body-text" v-html="$t(option.description)"></div>
            </div>
            <z-btn
              class="action-button"
              primary
              :data-cy="option.id"
              :text="option.buttonDescription"
              @click="$emit(option.event)"
            />
          </div>
        </template>
      </z-card>

      <language-select v-if="$vuetify.breakpoint.smAndDown" />
    </template>
  </z-card>
</template>

<script>
import { computed } from "vue"

import i18n from "@/i18n"

export default {
  props: {
    welcome: {
      type: Object,
      default: () => ({}),
    },
    hotel: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const options = computed(() => [
      {
        id: "checkin",
        event: "click:checkin",
        title: i18n.tc("welcome.web_checkin"),
        description: "welcome.web_checkin_description",
        buttonDescription: i18n.tc("welcome.go_to_guest_record"),
        icon: "$edit_outline",
        color: "var(--darkgreen)",
        isVisible: true,
      },
      {
        id: "services",
        event: "click:services",
        title: i18n.tc("welcome.online_services"),
        description: "welcome.online_services_description",
        buttonDescription: i18n.tc("welcome.go_to_online_services"),
        icon: "$room_service_rounded",
        color: "var(--orange)",
        isVisible: props.welcome.enableRedirect === true,
      },
      {
        id: "checkout",
        event: "click:checkout",
        title: i18n.tc("welcome.checkout"),
        description: "welcome.checkout_description",
        buttonDescription: i18n.tc("welcome.checkout_button_goto"),
        icon: "$exit_to_app_rounded",
        color: "var(--red)",
        isVisible:
          props.welcome.enableCheckout === true &&
          props.welcome.showCheckoutButton === true,
      },
    ])

    return {
      options,
    }
  },
}
</script>

<style scoped lang="scss">
.welcome-card {
  max-width: 450px;
  padding: 20px;
  display: grid;
  row-gap: 15px;
  justify-items: center;

  > * {
    text-align: center;
  }

  p {
    color: var(--black-800);
  }
}

.card-option {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  padding: 16px 24px;
  column-gap: 30px;
  row-gap: 20px;
  color: #333;
}

.action-button {
  grid-column: 1 / -1;
}

.card-option-text {
  display: grid;
  text-align: start;
  row-gap: 5px;
}

@media screen and (max-width: 350px) {
  .card-option {
    padding: 16px 16px;
    gap: 15px;
  }
}
</style>
